import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "компонент-filters"
    }}>{`Компонент `}<inlineCode parentName="h1">{`<Filters>`}</inlineCode></h1>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`alwaysVisible (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultFilters (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string[]`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`defaultFilters`}</inlineCode></strong>{` - массив `}<inlineCode parentName="li">{`name`}</inlineCode>{` компонента `}<inlineCode parentName="li">{`<Input>`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`alwaysVisible`}</inlineCode></strong>{` - если true - фильтры будут видны после перезагрузки страницы`}</li>
    </ul>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<List
    view="show"
    columns={columns}
    filters={
        <Filters>
            <TextInput name="title" placeholder="Title" />
            <TextInput name="body" placeholder="Body" />
        </Filters>
    }
    actions={
        <>
            <Button
                onClick={() => history.push("/posts/create")}
                type="primary"
            >
                Create
            </Button>
        </>
    }
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      